import {AppInputField, FormButton} from "../../../theme/styled-components/Form.styled";
import React, {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {PostalAddressI, UserAddressI} from "../../userInterfaces";
import {useAppSelector} from "../../../../app/hooks";
import {selectUser} from "../../userSlice";
import {userAddAddress} from "../../userRepository";
import CONFIG from "../../../../app/config";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../intl/translator";

interface Props {
    setStep: any,
    address: PostalAddressI
}

interface Inputs {
    city: string,
    postCode: string,
    street: string,
    streetNo: string,
    building: string,
    floor: string,
    flat: string,
    comments: string
}

export function AddAddressStep3({setStep, address}: Props) {
    const strings = {
        's_title': Translate('add_address_step_3_title'),
        's_save': Translate('save'),
        's_post_code': Translate('post_code'),
        's_street_name': Translate('street_name'),
        's_city': Translate('city'),
        's_street_no': Translate('street_number'),
        's_building_name': Translate('building_name'),
        's_floor': Translate('floor'),
        's_flat': Translate('flat_number'),
        's_address_notes': Translate('address_notes'),
    }
    const {register, handleSubmit, formState: {errors}, setValue} = useForm<Inputs>();
    const user = useAppSelector(selectUser);
    const navigate= useNavigate();

    useEffect(() => {
        setValue('city', address.DistrictEn);
        setValue('postCode', address.PostalCode);
        setValue('street', address.RoadNameEn);
    },[address, setValue])

    const onSubmit: SubmitHandler<Inputs> = data => {
        const userAddress: UserAddressI = {
            LocationID: address.ID,
            RoadName: address.RoadNameEn,
            RegisterUserID: user.RegisteredUserID,
            PostalCode: address.PostalCode,
            Municipality: address.MunicipalityEn,
            District: address.DistrictEn,
            Number: data.streetNo,
            FlatNo: data.flat ?? "",
            FloorNumber: data.floor ?? "",
            BuildingName: encodeURIComponent(data.building) ?? "",
            Details: encodeURIComponent(data.comments) ?? ""
        }
        if(user.RegisteredUserID !== CONFIG.GUEST_USER_ID){
            userAddAddress(userAddress).then(res => {
                navigate('/address/list')
            }).catch(e => {
                console.log(e)
            })
        }
    };

    return (
        <div className='step-3'>
            <h1>{strings.s_title}</h1>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_city} <span>*</span></label>
                <AppInputField
                    className={'small'}
                    border={'solid thin var(--theme-sky-blue)'}
                    {...register("city", {
                        required: true,
                        disabled: true,
                    })}/>
                {errors.city && <span className="text-red-600 text-sm">This field is required.</span>}
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_post_code} <span>*</span></label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("postCode", {
                    required: true,
                    disabled: true,
                })}/>
                {errors.postCode && <span className="text-red-600 text-sm">This field is required.</span>}
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_street_name} <span>*</span></label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("street", {
                    required: true,
                    disabled: true,
                })}/>
                {errors.street && <span className="text-red-600 text-sm">This field is required.</span>}
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_street_no} <span>*</span></label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("streetNo", {
                    required: true,
                })}/>
                {errors.streetNo && <span className="text-red-600 text-sm">This field is required.</span>}
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_building_name}</label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("building")}/>
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_floor}</label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("floor")}/>
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_flat}</label>
                <AppInputField
                    border={'solid thin var(--theme-sky-blue)'}
                    className={'small'} {...register("flat")}/>
            </div>
            <div className={'mb-3'}>
                <label className={'text-gray-400'}>{strings.s_address_notes}</label>
                <textarea
                    className="
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                     bg-clip-padding
                    border border-solid border-sky-500
                    rounded
                    transition
                    ease-in-out
                    mt-1
                    card-modded-bg
                    focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none
                  "

                    rows={4}
                    {...register("comments")}>

                </textarea>
            </div>
            <FormButton className="mt-6" background="var(--theme-sky-blue)"
                        onClick={handleSubmit(onSubmit)}>
                {strings.s_save}
            </FormButton>

        </div>
    )
}
